import React, { Fragment, useEffect, useState } from "react";
import toast, { Toaster } from 'react-hot-toast';

const ToastMessage = ({ message, type, onClose }) => {
    useEffect(() => {
        if (message != '') {
            toast.custom((t) => (
                <div
                    className={`${t.visible ? 'animate-enter' : 'animate-leave'
                        } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
                >
                    <div className="flex-1 w-0 p-4">
                        <div className="flex items-center">
                            <div className="flex-shrink-0 pt-0.5">
                                <img
                                    className="h-10 w-10 rounded-full"
                                    src={`${process.env.PUBLIC_URL}/images/logo.png`}
                                    alt=""
                                />
                            </div>
                            <div className="ml-3 flex-1">
                                {
                                    type === "error" ?
                                        <h3 className="text-reddish font-medium">Error</h3>
                                        : type === "success" ?
                                            <h3 className="text-green font-medium">Success</h3>
                                            : null
                                }
                                <p className="text-sm text-gray-500">
                                    {message}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="flex border-l border-gray-200">
                        <button
                            onClick={() => { onClose(); toast.dismiss(t.id) }}
                            className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                        >
                            Close
                        </button>
                    </div>
                </div>
            ))
        }

        return () => {
            onClose()
        };
    })
    return (
        <Fragment>
            <Toaster />
        </Fragment>
    )
}

export default ToastMessage;