import React, { Fragment, useState, useEffect } from "react";
import axiosRequest from "../../axiosRequest";
import { useSelector } from "react-redux";
import Dropdown from "./Dropdown";
import { HiChevronDown } from "react-icons/hi2";
import "./VersionDropdown.css"
import fetchRequest from "../../axiosRequest";
import { useDispatch } from 'react-redux';
import getProjectIdentifier from "../../utils/getProjectIdentifier";
import { errorInfoHandlerActions, generalLoaderActions } from "../../store";


const VersionDropdown = (props) => {
    const dispatch = useDispatch();
    const commentShow = useSelector(state => state.commenting.visible);
    const [projectVersion, setProjectVersion] = useState("");
    const [date, setDate] = useState("20 Mar, 2023")
    const [availableUpdates, setAvailableUpdates] = useState([]);
    const [updateNumber, setUpdateNumber] = useState(-1); // -1 = undefined, 0 = Baseline, 1 = Version 1, 2 = Version 2 ...

    // retrieve file data from server
    const [updateDetails, setUpdateDetails] = useState([]);

    // useEffect(() => {
    //     fetchRequest("project/getFilesForProject", "POST", {projectIdentifier: "895be50e-17e8-47c8-8367-f13d24ee87dd", app: localStorage.getItem("app")})
    //         .then((data)=>{
    //             console.log("data", data)
    //         })
    //         .catch(error => {
    //             console.error(error)
    //         });
    // }, []);

    useEffect(() => {
        axiosRequest("earlyWarning/getUpdateVersions", "POST", { projectIdentifier: getProjectIdentifier() })
            .then((data) => {
                if (data.status === 500 || data.status == null) {
                    // Handle the 500 error
                    console.error("Server returned a 500 error:", "getUpdateNumbers");
                    dispatch(errorInfoHandlerActions.showError(true));
                    // reject(new Error("Server returned a 500 error"));
                    return;
                }
                setAvailableUpdates(data.data.result);
                localStorage.setItem("availableUpdates", JSON.stringify(data.data.result));

                if (localStorage.getItem("updateNumber") === null || localStorage.getItem("updateNumber") === "undefined") {
                    // console.log("latest update", data.data.result[data.data.result.length - 1])
                    setUpdateNumber(data.data.result[data.data.result.length - 1]);
                    saveUpdateNumber(data.data.result[data.data.result.length - 1]);
                }


            })
            .catch((err) => {
                console.log(err.status)
            })
            .catch(error => {
                console.error(error)
            });
    }, []);

    useEffect(() => {
        const savedUpdateNumber = localStorage.getItem('updateNumber');
        if (savedUpdateNumber !== null) {
            switch (savedUpdateNumber) {
                case '0':
                    setProjectVersion('B');
                    setDate('20 Mar, 2023');
                    break;
                case '1':
                    setProjectVersion('V1');
                    setDate('24 Apr, 2023');
                    break;
                case '2':
                    setProjectVersion('V2');
                    setDate('01 May, 2023');
                    break;
                case '3':
                    setProjectVersion('V3');
                    setDate('15 Jul, 2023');
                    break;
                case '4':
                    setProjectVersion('V4');
                    setDate('15 Sept, 2023');
                    break;
                case '5':
                    setProjectVersion('V5');
                    setDate('11 Jan, 2024');
                    break;
                default:
                    setProjectVersion('V' + savedUpdateNumber);
                    setDate('20 Mar, 2024');
                    break;
            }

            setUpdateNumber(savedUpdateNumber);
        }
        else {
            // set version to the latest version
            setProjectVersion(availableUpdates[availableUpdates.length - 1] === 0 ? "B" : `V${availableUpdates[availableUpdates.length - 1]}`);
            setDate('20 Mar, 2023');
            saveUpdateNumber(availableUpdates[availableUpdates.length - 1]);
        }

        // fetchRequest("project/getFilesForProject", "POST", {projectIdentifier: "895be50e-17e8-47c8-8367-f13d24ee87dd", app: localStorage.getItem("app")})
        //     .then((data)=>{
        //         // console.log("data", data)
        //     })
        //     .catch(error => {
        //         // console.error(error)
        //     });

    }, [availableUpdates]);

    const closeOpenedDropdown = () => {
        if (document.querySelector(".dropdown.show > :first-child") !== null) {
            document.querySelector(".dropdown.show > :first-child").click();
        }
    }

    // save selected version in local storage as SelectedVersion
    const saveUpdateNumber = (version) => {
        setUpdateNumber(version)
        localStorage.setItem("updateNumber", version);
        // window.location.reload(false);
    }

    const reloadWithUpdate = (version) => {
        saveUpdateNumber(version)
        window.location.reload(false);
    }

    return (
        <Fragment>
            <Dropdown extraClass="proVerDrp">
                <button><span className={`v-${projectVersion === "B" ? "bl" : projectVersion}`}>{projectVersion}</span> <HiChevronDown /></button>
                <div className="dd-menu">
                    <ul>
                        <ul>
                            {/* versionDropdowns */}
                            {availableUpdates.map((update, index) => (
                                <li key={index}>
                                    <button
                                        onClick={() => {
                                            setProjectVersion(update === 0 ? "B" : `V${update}`);
                                            closeOpenedDropdown();
                                            reloadWithUpdate(update);
                                        }}
                                    >
                                        <span className={`v-${update === 0 ? "bl" : update}`}>{update === 0 ? "B" : `V${update}`}</span>
                                        {update === 0 ? "Baseline" : `Version ${update}`}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </ul>
                </div>
            </Dropdown>
            <div className="date">{date}</div>
            {
                commentShow && <div className="commentNode one">H</div>
            }
        </Fragment>
    )
};

export default VersionDropdown;