import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Navigation from "../TopNavigation/Navigation";
import Sidebar from "../Sidebar/Sidebar";
import TMCoreLoading from "../LoadingScreens/TMCore";
import VersionDropdown from "../UI/VersionDropdown";
import { timeManagerActions, modalActions } from "../../store";
import { HiChevronRight, HiChevronDown, HiOutlineCog } from "react-icons/hi2";
import { IoHomeSharp } from "react-icons/io5";
import Dropdown from "../UI/Dropdown";
import AddMember from "../UI/AddMember";
import axiosRequest from "../../axiosRequest";
import getProjectIdentifier from "../../utils/getProjectIdentifier";
import "../UI/Dropdown.css";
import "../UI/TablesToolBar.css";

const appNames = {
    "/neuro-dynamiq": "NeuroDynamiq",
    "/vivclima": "Vivclima",
    "/abacus": "Abacus",
    "/optima-res": "OptimaRes",
    "/numetra": "Numetra",
    "/risk-sentinel": "RiskSentinel",
    "/saif": "s AI f",
    "/alliance": "Alliance",
};

const Layout = ({ children }) => {
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const expended = useSelector((state) => state.sidebar.sidebarOpen);
    const activeFeature = useSelector((state) => state.timeManager.activeFeature);
    const isLoading = useSelector((state) => state.generalLoader.loaderVisible);
    const isAlliance = !!localStorage.getItem("projectType");
    // console.log("isAlliance", isAlliance)
    // const [switchView, setSwitchView] = useState(isAlliance);
    const internalActFea = useSelector(
        (state) => state.timeManager.internalActiveFea
    );
    const [newScenarioInfo, setnewScenarioInfo] = useState({});
    const featureHnadler = (payload) => {
        dispatch(timeManagerActions.changeFeature(payload));
    };
    // Scenario Modeling
    const newScenarioValueHandler = (e) => {
        let utcDate = new Date();
        const utcYear = utcDate.getUTCFullYear();
        const utcMonth = String(utcDate.getUTCMonth() + 1).padStart(2, "0");
        const utcDay = String(utcDate.getUTCDate()).padStart(2, "0");
        utcDate = `${utcYear}-${utcMonth}-${utcDay}`;
        setnewScenarioInfo({
            name: e.target.value,
            image:
                "../../../img/scanarioPlanning/" + e.target.getAttribute("data-icon"),
            icon: e.target.getAttribute("data-icon"),
            dateCreated: utcDate,
        });
    };

    const addScenarioHandler = (e) => {
        console.log("newScenario ", newScenarioInfo);
        axiosRequest("scenarios/createScenario", "POST", {
            projectIdentifier: getProjectIdentifier(),
            scenario: newScenarioInfo,
        })
            .then((data) => {
                console.log(data);
                dispatch(modalActions.getScenario());
                // setdd_visible(false)
            })
            .catch((err) => {
                console.log(err);
            });
    };
    // Scenario Modeling

    return (
        <>
            <Navigation switchView={isAlliance} />
            <Sidebar switchView={isAlliance} />
            <div className={expended ? "workingArea expended" : "workingArea"}>
                <div className="container">
                    {isLoading ? <TMCoreLoading /> : ""}
                    <div className="flex flex-wrap justify-between">
                        <div>
                            <div className="title-version">
                                <h1>{internalActFea ? internalActFea : "Overview"}</h1>
                                <VersionDropdown />
                            </div>
                            {
                                isAlliance ?
                                    <ul className="breadcrumb flex flex-wrap list-none items-center mb-10">
                                        <li
                                            className="inline-flex flex-wrap cursor-pointer"
                                            onClick={() => navigate("/")}
                                        >
                                            <IoHomeSharp color="#858585" /> <HiChevronRight />
                                        </li>
                                        <li
                                            className="inline-flex flex-wrap"
                                        >
                                            {appNames[location.pathname]} <HiChevronRight />
                                        </li>
                                        <li className="inline-flex flex-wrap current">
                                            {activeFeature}
                                        </li>
                                    </ul> : <ul className="breadcrumb flex flex-wrap list-none items-center mb-10">
                                        <li
                                            className="inline-flex flex-wrap cursor-pointer"
                                            onClick={() => featureHnadler("Core")}
                                        >
                                            Home <HiChevronRight />
                                        </li>
                                        <li className="inline-flex flex-wrap current">
                                            {activeFeature}
                                        </li>
                                    </ul>
                            }
                        </div>
                        <div className="flex flex-wrap items-start mt-4">
                            {activeFeature !== "Scenario Modeling" ? (
                                <>
                                    <button className="addFile">
                                        <img src="./images/icons/addFilePlus.svg" alt="addFile" />{" "}
                                        Add File
                                    </button>
                                </>
                            ) : (
                                <>
                                    <Dropdown extraClass="blu-bg mr-8">
                                        <button className="flex flex-wrap gap-2.5 items-center text-primary font-medium">
                                            <img src="./images/icons/plus.svg" alt="add" /> New Model
                                        </button>
                                        <div className="dd-menu">
                                            <label htmlFor="CostScenario">
                                                <input
                                                    id="CostScenario"
                                                    name="newScenarioType"
                                                    type="radio"
                                                    value="Cost Scenario"
                                                    data-icon="cps.svg"
                                                    onChange={newScenarioValueHandler}
                                                />{" "}
                                                <img
                                                    src={`${process.env.PUBLIC_URL}/images/icons/scenarioModels/cps.svg`}
                                                    alt="cps"
                                                />{" "}
                                                Cost Scenario
                                            </label>
                                            <label htmlFor="Schedule">
                                                <input
                                                    id="Schedule"
                                                    name="newScenarioType"
                                                    type="radio"
                                                    value="Schedule"
                                                    data-icon="construction.svg"
                                                    onChange={newScenarioValueHandler}
                                                />{" "}
                                                <img
                                                    src={`${process.env.PUBLIC_URL}/images/icons/scenarioModels/construction.svg`}
                                                    alt="construction"
                                                />{" "}
                                                Schedule
                                            </label>
                                            <label htmlFor="RiskScenario">
                                                <input
                                                    id="RiskScenario"
                                                    name="newScenarioType"
                                                    type="radio"
                                                    value="Risk Scenario"
                                                    data-icon="nuclear.svg"
                                                    onChange={newScenarioValueHandler}
                                                />{" "}
                                                <img
                                                    src={`${process.env.PUBLIC_URL}/images/icons/scenarioModels/nuclear.svg`}
                                                    alt="nuclear"
                                                />{" "}
                                                Risk Scenario
                                            </label>
                                            <button
                                                className="btn doneBtn"
                                                onClick={addScenarioHandler}
                                            >
                                                Done
                                            </button>
                                        </div>
                                    </Dropdown>
                                </>
                            )}
                            {/* add members <start> */}
                            <AddMember />
                            {/* add members <end> */}
                        </div>
                    </div>
                    {children}
                </div>
            </div>
        </>
    )
};

export default Layout;