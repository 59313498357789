import React from 'react'
import { Tooltip } from "react-tooltip";

const SideBarMenuItem = (props) => {
    const { activeFeature, onClick, imgSrc, title, disable, tootTipText, tooltipId } = props;

    const handleOnClick = () => {
        if (!disable) {
            onClick && onClick();
        }
    }

    return (
        <>
            <li data-tooltip-id={`ddSidebarMenu${tooltipId}`} data-tooltip-content={tootTipText || "This feature is disabled"}>
                <button disabled={disable} onClick={handleOnClick} className={`${activeFeature === title ? "active" : ""}`} style={{ color: disable ? 'grey' : '' }}>
                    <img src={imgSrc} alt={title + 'alt'} />
                    <span>{title}</span>
                </button>
            </li>
            {disable && <Tooltip id={`ddSidebarMenu${tooltipId}`} place="right" />}
        </>
    )
}

export default SideBarMenuItem