import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import "./style.css";

const TMCoreLoading = (props) => {
  const navigate = useNavigate();
  const loadingVal = useSelector(state => state.generalLoader.progress);
  const numberOfRequests = useSelector(state => state.generalLoader.numberOfRequests);
  const errorMsg = useSelector(state => state.generalLoader.error);
  const [loadingFill, setLoadingFill] = useState(loadingVal);
  // const numberOfRequests = 10;
  // const initialDividend = 100/numberOfRequests;
  const [dividend, setDivident] = useState(100 / numberOfRequests);
  // const dividend = 10;



  useEffect(() => {
    const loaderFill = setInterval(() => {
      if (loadingFill < dividend) {
        setLoadingFill(prevLoadingFill => prevLoadingFill + 5);
      }
    }, 1000);

    return () => clearInterval(loaderFill);
  }, [loadingFill, dividend]);

  useEffect(() => {
    setLoadingFill(loadingVal);
  }, [loadingVal])

  return (
    <div className="TMCoreLoading loader-cont" style={{ '--mv-loading-bar-width': `${loadingFill}%` }}>
      {errorMsg &&
        <p>Error! Loading Some Data <button onClick={() => { navigate("/apps") }} className="text-primary">Please Try Again</button>.</p>
      }
    </div>
  )
};

export default TMCoreLoading;
