import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import EditScenarioModal from "../ScenarioPlanning/EditScenarioModal";
import { FiUpload, FiCheckCircle } from "react-icons/fi";

import { modalActions } from "../../store";

import "./ChangePasswordModal.css"

const ChangeProfileModal = (props) => {
    const dispatch = useDispatch();
    const [profileChanged, setProfileChnaged] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [isDragOver, setIsDragOver] = useState(false);

    const handleDrop = (e) => {
        e.preventDefault();
        setIsDragOver(false);
        const files = e.dataTransfer.files;
        if (files.length > 0) {
            // setSelectedFile(files[0]);
            const file = files[0];
            if (validateFile(file)) {
                setSelectedFile(file);
            } else {
                alert("Please select a PNG or JPG file with a maximum size of 2 MB.");
            }
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault()
    }

    const handleDragEnter = (e) => {
        e.preventDefault();
        setIsDragOver(true);
    };

    const handleDragLeave = () => {
        setIsDragOver(false);
    };

    const handleFileInputChange = (e) => {
        const file = e.target.files[0];
        setSelectedFile(file);
    }

    const validateFile = (file) => {
        if (file) {
            const fileType = file.type;
            const fileSize = file.size;

            if (
                (fileType === 'image/png' || fileType === 'image/jpeg') &&
                fileSize <= 2 * 1024 * 1024 // 2 MB in bytes
            ) {
                return true;
            }
        }
        return false;
    }

    return (
        <EditScenarioModal mainClass="passwordProfile" title="Change your Profile Picture">
            {
                !profileChanged ?
                <div className="passCont">
                    <div className={`profileDropArea ${isDragOver ? "dropping" : ""}`} onDrop={handleDrop} onDragOver={handleDragOver} onDragEnter={handleDragEnter} onDragLeave={handleDragLeave} >
                        {selectedFile ? (
                            <Fragment>
                                <FiCheckCircle className="checkIcon" />
                                <p className="font-medium">Picture Loaded "{selectedFile.name}"</p>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <FiUpload className="uploadIcon" />
                                <p className="font-medium">Drag your picture here</p>
                            </Fragment>
                        )}
                    </div>
                    <span className="block text-center uppercase my-4 text-20 font-medium text-primary">Or</span>
                    <label htmlFor="profileUpload">
                        Browse
                        <input type="file" id="profileUpload" onChange={handleFileInputChange} accept=".png, .jpg, .jpeg" style={{display: "none"}} />
                    </label>
                    <button className={!selectedFile ? "disabled" : ""} {...(selectedFile ? {onClick: ()=>setProfileChnaged(true)} : "")}>Upload</button>
                </div>
                :
                <div className="passCont">
                    <div className="success-box">
                        <FiCheckCircle />
                        <p>Profile has been changed</p>
                    </div>
                    <button onClick={()=> dispatch(modalActions.toggle(""))}>Close</button>
                </div>
            }
        </EditScenarioModal>
    )
};

export default ChangeProfileModal;