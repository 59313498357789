import { Fragment, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Tooltip } from 'react-tooltip';
import { GiProgression } from "react-icons/gi";
import { GrShieldSecurity } from "react-icons/gr";
import Dropdown from "../UI/Dropdown";
import { FiEdit, FiLogOut, FiCheckCircle, FiToggleLeft, FiToggleRight } from "react-icons/fi";
import { HiChevronRight } from "react-icons/hi2";
import { IoIosInformationCircleOutline } from "react-icons/io";

import ChangePasswordModal from "../UI/ChangePasswordModal";
import ChangeProfileModal from "../UI/ChangeProfileModal";
import closeOpenedDropdown from "../../generals/DropdownCloser";
import { modalActions, vAActions, chatActions, commentingActions, timeManagerActions, vivclimaActions, costActions, ResourceOptimizerActions } from "../../store";

import "./Navigation.css"
import { LINK_MENU_ITEM } from "../Sidebar/Sidebar";
import SideBarMenuItem from "../Sidebar/SideBarMenuItem";

const Navigation = (props) => {
    const { switchView, handleToggleView } = props
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const modalType = useSelector(state => state.modal.modalType);
    const commentShow = useSelector(state => state.commenting.visible);
    const { pageComment } = useSelector(
        (state) => state.commenting
    );
    const isLoading = useSelector(state => state.generalLoader.loaderVisible);
    const [notifCount, setNotifCount] = useState(10);
    const [allNotif, setAllNotif] = useState(true);
    const [editUsername, setEditUsername] = useState(false);
    const [activeElement, setActiveElement] = useState("");
    let activeFeature = activeElement;

    // const [showPasswordModal, setShowPasswordModal] = useState

    const editUsernameHandler = () => {
        setEditUsername(!editUsername)
        // console.log("editUsername", !editUsername)
        if (!editUsername) {
            setTimeout(() => {
                document.querySelector("input#username").focus();
            }, 100);
        }
    }
    const featureHnadler = (payload) => {
        location.pathname === "/neuro-dynamiq" ?
            dispatch(timeManagerActions.changeFeature(payload))
            : location.pathname === "/vivclima" ?
                dispatch(vivclimaActions.changeFeature(payload))
                : location.pathname === "/numetra" ?
                    dispatch(costActions.changeFeature(payload))
                    : location.pathname === "/optima-res" ?
                        dispatch(ResourceOptimizerActions.changeFeature(payload))
                        : dispatch(timeManagerActions.changeFeature(payload))
        setActiveElement(payload);
    }

    const showPassModalHandler = () => {
        closeOpenedDropdown();
        dispatch(modalActions.toggle("ChangePasswordModal"));
    }

    const showProfileModalHandler = () => {
        closeOpenedDropdown();
        dispatch(modalActions.toggle("ChangeProfileModal"));
    }

    const showCommentsHandler = () => {
        closeOpenedDropdown();
        dispatch(commentingActions.toggle());
    }

    const panelToggleHandler = (e) => {
        e.stopPropagation();
        // dispatch(rightSidebarActions.toggle());
        dispatch(commentingActions.toggle());
    }

    const stopPropagtionHandler = (e) => {
        e.stopPropagation()
    }

    // const logoutHandler = () => {
    //     localStorage.clear();
    //     document.cookie = "email=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    // }

    const logoutHandler = () => {
        dispatch(chatActions.resetState())
        localStorage.clear()
        document.cookie = "email=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        // navigate("/login")
        window.location.reload();
    }

    const projectName = localStorage.getItem("projectName");

    return (
        <Fragment>
            <nav className={`py-2 ${isLoading ? "loading" : ""}`}>
                <div className="container flex flex-wrap justify-between">
                    {!switchView ?
                        <div className="logo flex flex-wrap gap-4 items-center text-20">
                            {
                                location.pathname === "/saif" ?
                                    <Fragment><img src={`${process.env.PUBLIC_URL}/images/AppScreen/CV.svg`} alt="logo" className="w-10 h-10" /> s AI f</Fragment>
                                    : location.pathname === "/vivclima" ?
                                        <Fragment><img src={`${process.env.PUBLIC_URL}/images/products/vivclima.svg`} alt="logo" className="w-10 h-10" /> Vivclima</Fragment>
                                        : location.pathname === "/numetra" ?
                                            <Fragment><img src={`${process.env.PUBLIC_URL}/images/AppScreen/cost.svg`} alt="logo" className="w-10 h-10" /> Numetra</Fragment>
                                            : location.pathname === "/enterprise-directory" ?
                                                <Fragment><img src={`${process.env.PUBLIC_URL}/images/icons/projectdirectory.svg`} alt="logo" /> Enterprise Directory</Fragment>
                                                : location.pathname === "/neuro-dynamiq" ?
                                                    <Fragment><img src={`${process.env.PUBLIC_URL}/images/products/projectManager.svg`} alt="logo" /> NeuroDynamiq</Fragment>
                                                    : location.pathname === "/abacus" ?
                                                        <Fragment><img src={`${process.env.PUBLIC_URL}/images/AppScreen/estimation.svg`} alt="logo" className="w-10 h-10" /> Abacus</Fragment>
                                                        : location.pathname === "/optima-res" ?
                                                            <Fragment><img src={`${process.env.PUBLIC_URL}/images/AppScreen/optimizer.svg`} alt="logo" className="w-10 h-10" /> OptimaRes</Fragment>
                                                            : location.pathname === "/risk-sentinel" ?
                                                                <Fragment><img src={`${process.env.PUBLIC_URL}/images/AppScreen/optimizer.svg`} alt="logo" className="w-10 h-10" /> RiskSentinel</Fragment>
                                                                : location.pathname === "/Gulermak" ?
                                                                    <Fragment><img src={`${process.env.PUBLIC_URL}/images/gulermak/gulermak.png`} alt="logo" className="w-10 h-10" /> Gulermak</Fragment>
                                                                    : location.pathname === "/alliance" ?
                                                                        <Fragment><img src={`${process.env.PUBLIC_URL}/images/icons/timemanager/sce_mod.svg`} alt="logo" className="w-8 h-8" /> Alliance</Fragment>
                                                                        :
                                                                        <Fragment><img src={`${process.env.PUBLIC_URL}/images/products/projectManager.svg`} alt="logo" /> App Name</Fragment>
                            }
                        </div> : <div className="logo flex flex-wrap gap-4 items-center text-20">
                            <Fragment><img src={`${process.env.PUBLIC_URL}/images/icons/timemanager/sce_mod.svg`} alt="logo" className="w-8 h-8" /> Alliance</Fragment>
                        </div>
                    }
                    <div className="nav-items">
                        <ul className="flex flex-wrap gap-8 items-center">
                            {/* {location.pathname === "/alliance" && projectName === "Eastern Freeway – Burke to Tram Alliance" && <li><button data-tooltip-id="navbarTooltip" data-tooltip-content="Toggle Sidebar view" style={{ fontSize: '200%' }} onClick={handleToggleView}>{!switchView ? <FiToggleLeft /> : <FiToggleRight />}</button></li>} */}
                            <li className="colabItem">
                                <Dropdown extraClass="width-190">
                                    <button data-tooltip-id="navbarTooltip" data-tooltip-content="MavJam"><img src="/images/icons/users.svg" alt="users" /></button>
                                    <div className="dd-menu font-semibold text-center bb-1">
                                        <ul>
                                            <li><button onClick={() => { closeOpenedDropdown(); dispatch(chatActions.toggle()); }}>Chat</button></li>
                                            <li><button onClick={showCommentsHandler}>Comment</button></li>
                                            {
                                                location.pathname !== "/enterprise-directory" ?
                                                    <li><button onClick={() => featureHnadler("Action Tracker")}>Action Tracker</button></li> : null
                                            }

                                        </ul>
                                    </div>
                                </Dropdown>
                            </li>
                            <li><Link to="#" data-tooltip-id="navbarTooltip" data-tooltip-content="Automation" onClick={() => featureHnadler("Automation")}><img src={`${process.env.PUBLIC_URL}/images/icons/sidebar/rocket.svg`} alt="rocket" /></Link></li>
                            <li><button data-tooltip-id="navbarTooltip" data-tooltip-content="Virtual Assistant" onClick={() => dispatch(vAActions.toggle())}><img src={`${process.env.PUBLIC_URL}/images/icons/bot.svg`} alt="bot" /></button></li>
                            <li className="notifItem" data-tooltip-id="navbarTooltip" data-tooltip-content="Notification">
                                <Dropdown>
                                    <button><img src={`${process.env.PUBLIC_URL}/images/icons/sidebar/noti.svg`} alt="notification" />
                                        {/* {notifCount > 0 ? <span className="badge count absolute inline-flex justify-center items-center text-10 font-medium !p-0 !bg-reddish text-white">{notifCount > 9 ? `9+` : notifCount}</span> : ""} */}
                                    </button>
                                    <div className="dd-menu">
                                        <div className="notifList">
                                            <h3 className="text-24 mb-3">Notifications</h3>
                                            <div className="mainBtns flex flex-wrap gap-4 mb-6">
                                                <button className={allNotif ? "active" : ""} onClick={() => setAllNotif(true)}>All</button>
                                                <button className={!allNotif ? "active" : ""} onClick={() => setAllNotif(false)}>Unread</button>
                                            </div>
                                            {/* <ul>
                                                {
                                                    location.pathname === "/vivclima" ?
                                                        <li className="single-notif unread">
                                                            <div className="avatar icon">
                                                                <IoIosInformationCircleOutline />
                                                            </div>
                                                            <div className="text">
                                                                <h4 className="title">General Information</h4>
                                                                <p className="msg">2022 carbon taxes change, new regulations coming in.</p>
                                                                <time>Just now</time>
                                                            </div>
                                                        </li>
                                                        : null
                                                }
                                                {
                                                    allNotif ? (
                                                        <li className="single-notif">
                                                            <div className="avatar">
                                                                <img src={`${process.env.PUBLIC_URL}/images/notifAvtr1.svg`} alt="user-img" />
                                                            </div>
                                                            <div className="text">
                                                                <h4 className="title">Invitation</h4>
                                                                <p className="msg">you have been added into <Link to="">Andarko Piling Project</Link></p>
                                                                <p className="ref">by Nelson Jeff</p>
                                                                <time>about an hour ago</time>
                                                            </div>
                                                        </li>
                                                    ) : ""
                                                }
                                                <li className="single-notif unread">
                                                    <div className="avatar">
                                                        <img src={`${process.env.PUBLIC_URL}/images/notifAvtr3.svg`} alt="user-img" />
                                                    </div>
                                                    <div className="text">
                                                        <h4 className="title">Message</h4>
                                                        <p className="msg">Alexa message you <Link to="">Andarko Piling Project</Link></p>
                                                        <time>Just now</time>
                                                    </div>
                                                </li>
                                                <li className="single-notif unread">
                                                    <div className="avatar">
                                                        <img src={`${process.env.PUBLIC_URL}/images/notifAvtr2.svg`} alt="user-img" />
                                                    </div>
                                                    <div className="text">
                                                        <h4 className="title">Engagement</h4>
                                                        <p className="msg">Alexa like your recommendation in <Link to="">Andarko Piling Project</Link></p>
                                                        <time>Just now</time>
                                                    </div>
                                                </li>
                                                {
                                                    allNotif ? (
                                                        <li className="single-notif">
                                                            <div className="avatar icon">
                                                                <img src={`${process.env.PUBLIC_URL}/images/icons/email.svg`} alt="email" />
                                                            </div>
                                                            <div className="text">
                                                                <h4 className="title">Email Verification</h4>
                                                                <p className="msg">Congratulation your Email Has been Verified</p>
                                                                <time>Just now</time>
                                                            </div>
                                                        </li>
                                                    ) : ""
                                                }

                                            </ul> */}
                                            {/* <ul>
                                                <li><Link to="#"><img src={`${process.env.PUBLIC_URL}/images/products/calendar.svg`} alt="calendar" /> NeuroDynamiq</Link></li>
                                                <li><Link to="#"><img src={`${process.env.PUBLIC_URL}/images/products/vivclima.svg`} alt="vivclima" />  VivClima</Link></li>
                                                <li><Link to="#"><img src={`${process.env.PUBLIC_URL}/images/products/projectManager.svg`} alt="project manager" /> Project manager</Link></li>
                                                <li><Link to="#"><img src={`${process.env.PUBLIC_URL}/images/products/com_vision.svg`} alt="computer vision" /> Computer vision</Link></li>
                                            </ul> */}
                                        </div>
                                    </div>
                                </Dropdown>
                            </li>
                            <li data-tooltip-id="navbarTooltip" data-tooltip-content="Apps">
                                <Dropdown extraClass="apps width-190">
                                    <button className="p-2"><img src={`${process.env.PUBLIC_URL}/images/icons/sidebar/apps.svg`} alt="Apps" /></button>
                                    <div className="dd-menu">
                                        {!switchView ? <ul>
                                            {LINK_MENU_ITEM.map((item, index) => <li><Link to={item.path}><img src={`${process.env.PUBLIC_URL}${item.imgSrc}`} alt={item.text} /> {item.text}</Link></li>)}
                                            {/* 
                                            <li><Link to="/ProjectManager"><img src={`${process.env.PUBLIC_URL}/images/icons/projectdirectory.svg`} alt="project manager" /> Enterprise Directory</Link></li>
                                            <li><Link to="/time-manager"><img src={`${process.env.PUBLIC_URL}/images/products/calendar.svg`} alt="calendar" /> NeuroDynamiq</Link></li>
                                            <li><Link to="/vivclima"><img src={`${process.env.PUBLIC_URL}/images/products/vivclima.svg`} alt="vivclima" />  VivClima</Link></li>
                                            <li><Link to="/estimation"><img src={`${process.env.PUBLIC_URL}/images/estimation.png`} alt="Estimation" /> Abacus</Link></li>
                                            <li><Link to="/ResourceOptimizer"><img src={`${process.env.PUBLIC_URL}/images/AppScreen/optimizer.svg`} alt="Estimation" /> OptimaRes</Link></li>
                                            <li><Link to="/numetra"><img src={`${process.env.PUBLIC_URL}/images/AppScreen/cost.svg`} alt="cost manager" /> Numetra</Link></li>
                                            <li><Link to="/riskmanager"><img src={`${process.env.PUBLIC_URL}/images/AppScreen/RM.svg`} alt="RiskSentinel" /> RiskSentinel</Link></li>
                                            <li><Link to="/saif"><img src={`${process.env.PUBLIC_URL}/images/products/com_vision.svg`} alt="s AI f" /> s AI f</Link></li> */}
                                        </ul> : <ul>
                                            <li><Link to="/enterprise-directory"><img src={`${process.env.PUBLIC_URL}/images/icons/projectdirectory.svg`} alt="project manager" /> Enterprise Directory</Link></li>
                                            <li><button onClick={() => featureHnadler("Core")} className={activeFeature === "Core" ? "active" : ""}><img src={`${process.env.PUBLIC_URL}/images/icons/timemanager/core.svg`} alt="core" /> <span>Core</span></button></li>
                                            <li><button onClick={() => featureHnadler("Oculus DV")} className={activeFeature === "Oculus DV" ? "active" : ""} ><img src={`${process.env.PUBLIC_URL}/images/icons/timemanager/oculus.svg`} alt="oculus" /> <span>Oculus DV</span></button></li>
                                            <SideBarMenuItem tooltipId='andon' disable={false} onClick={() => featureHnadler("Andon")} activeFeature={activeElement} imgSrc={`${process.env.PUBLIC_URL}/images/icons/timemanager/andon.svg`} title="Andon" />
                                            {
                                                location.pathname !== "/visionai" &&
                                                    location.pathname !== "/vivclima" &&
                                                    location.pathname !== "/optima-res" &&
                                                    // location.pathname !== "/riskmanager" &&
                                                    location.pathname !== "/abacus" &&
                                                    location.pathname !== "/saif" ?
                                                    <Fragment>
                                                        <SideBarMenuItem tooltipId='gigo' disable={false} onClick={() => featureHnadler("GIGO")} activeFeature={'activeElement'} imgSrc={`${process.env.PUBLIC_URL}/images/icons/timemanager/gigo.svg`} title="GIGO" />
                                                        {
                                                            location.pathname !== ("/numetra" && "/abacus" && "/vivclima" && "/risk-sentinel") ?
                                                                <SideBarMenuItem tooltipId='sce_mod' disable={false} onClick={() => featureHnadler("Scenario Modeling")} activeFeature={activeElement} imgSrc={`${process.env.PUBLIC_URL}/images/icons/timemanager/sce_mod.svg`} title="Scenario Modeling" />
                                                                // <li><button onClick={() => featureHnadler("Scenario Modeling")} className={activeFeature === "Scenario Modeling" ? "active" : ""}><img src={`${process.env.PUBLIC_URL}/images/icons/timemanager/sce_mod.svg`} alt="sce_mod" /> <span>Scenario Modeling</span></button></li>
                                                                : null
                                                        }
                                                    </Fragment>
                                                    : null
                                            }
                                            {
                                                location.pathname == ("/risk-sentinel") ?
                                                    <Fragment>
                                                        <li><button onClick={() => featureHnadler("Risk Modelling")} className={activeFeature === "Risk Modelling" ? "active" : ""}><GrShieldSecurity className="h-6 w-6 ml-[3px]" /> <span>Risk Modelling</span></button></li>
                                                        {/* <li><button onClick={()=>featureHnadler("RM Forms")} className={activeFeature === "RM Forms" ? "active" : ""}><img src={`${process.env.PUBLIC_URL}/images/icons/rmForm.svg`} alt="RM Forms" /> <span>RM Forms</span></button></li> */}
                                                    </Fragment>
                                                    : null
                                            }
                                            {
                                                location.pathname == ("/optima-res") ?
                                                    <Fragment>
                                                        <li><button onClick={() => featureHnadler("Progress Tracker")} className={activeFeature === "Progress Tracker" ? "active" : ""}><GiProgression /> <span>Progress Tracker</span></button></li>
                                                        <li><button onClick={() => featureHnadler("+ Resources")} className={activeFeature === "+ Resources" ? "active" : ""}><img src={`${process.env.PUBLIC_URL}/images/icons/timemanager/gigo.svg`} alt="+ Resources" /> <span>+ Resources</span></button></li>
                                                    </Fragment>
                                                    : null
                                            }
                                            {
                                                location.pathname === "/vivclima" ?
                                                    <Fragment>
                                                        <li><button onClick={() => featureHnadler("Air Quality")} className={activeFeature === "Air Quality" ? "active" : ""}><img src={`${process.env.PUBLIC_URL}/images/icons/sidebar/airquality.png`} style={{ width: "28px", height: "28px" }} alt="AirQuality" /> <span>Air Quality</span></button></li>
                                                        <li><button onClick={() => featureHnadler("Climate Risk Assesment")} className={activeFeature === "Climate Risk Assesment" ? "active" : ""}><img src={`${process.env.PUBLIC_URL}/images/icons/sidebar/climaterisk.png`} style={{ width: "28px", height: "28px" }} alt="Climate Risk Assesment" /> <span>Climate Risk</span></button></li>
                                                    </Fragment>
                                                    : null
                                            }
                                            {
                                                location.pathname === "/abacus" ?
                                                    <li><button onClick={() => featureHnadler("Build your Bid")} className={activeFeature === "Build your Bid" ? "active" : ""}><img src={`${process.env.PUBLIC_URL}/images/icons/sidebar/airquality.png`} style={{ width: "28px", height: "28px" }} alt="Build your Bid" /> <span>Build your Bid</span></button></li>
                                                    : null
                                            }
                                        </ul>}
                                    </div>
                                </Dropdown>
                            </li>
                            <li>
                                <Dropdown extraClass="logout">
                                    <button className="avatar"><img src={`${process.env.PUBLIC_URL}/images/avatarmain.jpg`} alt="user-img" /></button>
                                    {/* <div className="avatar">
                                        <img src={`${process.env.PUBLIC_URL}/images/avatar.jpg`} alt="user-img" />
                                    </div> */}
                                    <div className="dd-menu">
                                        <div className="username">
                                            <div className="pic">
                                                <img src={`${process.env.PUBLIC_URL}/images/avatarmain.jpg`} alt="user-img" />
                                            </div>
                                            <div className="name-input">
                                                <p style={{ visibility: !editUsername ? "" : "hidden" }}>{localStorage.getItem("firstName")}</p>
                                                <input id="username" type="text" autoFocus style={{ visibility: !editUsername ? "hidden" : "" }} />
                                            </div>
                                            {
                                                !editUsername ?
                                                    <button className="editUsername" onClick={editUsernameHandler}><FiEdit /></button>
                                                    :
                                                    <button className="editUsername" onClick={editUsernameHandler}><FiCheckCircle /></button>
                                            }

                                        </div>
                                        <p style={{ color: "#ADADAD" }}>Account Settings</p>
                                        <ul>
                                            <li><button onClick={showPassModalHandler}>Change Password <HiChevronRight /></button></li>
                                            <li><button onClick={showProfileModalHandler}>Change Profile Picture <HiChevronRight /></button></li>
                                        </ul>
                                        {/* on logout, clear cookies and localstorage before redirecting to login*/}
                                        <button className="logoutBtn" onClick={logoutHandler}><FiLogOut /> Sign Out{/* <Link to="/login"></Link> */}</button>
                                    </div>
                                </Dropdown>
                            </li>
                        </ul>
                    </div>
                </div>
                <Tooltip id="navbarTooltip" place="bottom" />
            </nav>
            {
                modalType === "ChangePasswordModal" || modalType === "ChangeProfileModal" ?
                    ReactDOM.createPortal(
                        modalType === "ChangePasswordModal" ?
                            <ChangePasswordModal />
                            : modalType === "ChangeProfileModal" ?
                                <ChangeProfileModal />
                                : null,
                        document.getElementById('modal-root')
                    ) : null
            }
            {
                commentShow &&
                <div id="sidebar" className="right-side fixed w-screen open" onClick={panelToggleHandler}>
                    <div className="inner-content fixed pt-16 pl-10 pr-5 pb-8" onClick={stopPropagtionHandler}>
                        <button className="close absolute" title="Close" onClick={panelToggleHandler}><img src="/images/icons/openPanel.svg" alt="backward" /></button>
                        <h2 className="font-microgramma mb-8">Comments</h2>
                        <div className="inner-block fullHeight">
                            <div className="comments-log">
                                <div className="comment">
                                    <span className="avatar">H</span>
                                    <div className="info">
                                        <p className="username">Herberth Escobar</p>
                                        <span className="time">2 days ago</span>
                                    </div>
                                    <div className="desc">
                                        <p>Compare stats with version 3</p>
                                    </div>
                                </div>
                                <div className="comment">
                                    <span className="avatar">P</span>
                                    <div className="info">
                                        <p className="username">Prashita Rao</p>
                                        <span className="time">2 days ago</span>
                                    </div>
                                    <div className="desc">
                                        <p>Please reduce duration</p>
                                    </div>
                                </div>
                                <div className="comment">
                                    <span className="avatar">H</span>
                                    <div className="info">
                                        <p className="username">Herberth Escobar</p>
                                        <span className="time">3 days ago</span>
                                    </div>
                                    <div className="desc">
                                        <p>Dates are conflicting with our new piling project</p>
                                    </div>
                                </div>
                                <div className="comment">
                                    <span className="avatar">R</span>
                                    <div className="info">
                                        <p className="username">Rogene Lacanienta</p>
                                        <span className="time">4 days ago</span>
                                    </div>
                                    <div className="desc">
                                        <p>add <span className="tags text-primary">@arzaan</span> for tracking</p>
                                    </div>
                                </div>
                                <div className="comment">
                                    <span className="avatar">A</span>
                                    <div className="info">
                                        <p className="username">Anusha Saad</p>
                                        <span className="time">6 days ago</span>
                                    </div>
                                    <div className="desc">
                                        <p>Please review again</p>
                                    </div>
                                </div>
                                <div className="comment">
                                    <span className="avatar">B</span>
                                    <div className="info">
                                        <p className="username">Basit Khan</p>
                                        <span className="time">10 days ago</span>
                                    </div>
                                    <div className="desc">
                                        <p>Results are getting better!</p>
                                    </div>
                                </div>
                                {pageComment?.map((item) => {
                                    const { userName, comment, timeStamp } = item;

                                    return <div key={item.id} className="comment">
                                        <span className="avatar">H</span>
                                        <div className="info">
                                            <p className="username">{userName}</p>
                                            <span className="time">{timeStamp}</span>
                                        </div>
                                        <div className="desc">
                                            <p>{comment}</p>
                                        </div>
                                    </div>
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            }
        </Fragment>
    )
};

export default Navigation;
