import { useEffect } from "react";
import { Outlet, Navigate, useNavigate, useLocation } from "react-router-dom";
import axiosRequest from "../axiosRequest";
import Layout from "../components/Layout";

const PrivateRoute = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const loggedIn = localStorage.getItem("token");
  useEffect(() => {
    if (loggedIn) {
      console.log("loggedInloggedInloggedIn", loggedIn)
      // navigate("/time-manager")
      axiosRequest("login/validateToken", "POST")
    }
  }, [])    
  return loggedIn && location.pathname === "/" ? <Outlet /> : loggedIn && location.pathname !== "/" ? <Layout><Outlet /></Layout> : <Navigate to="/login" />;
};
export default PrivateRoute;