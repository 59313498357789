export const envVariables = {
  host: "https://demo-api.mavryck.com/service/PMIntelCore/rest/",

  getBasename: () => {
    const BASENAME = "__BASENAME__"; // DO NOT REMOVE OR MODIFY, very silly way of hanlding injecting basename into env variables... need better solution

    // If not replaced dynamically, fallback to "/"
    if (BASENAME === "__BASENAME__") {
      return "/";
    }

    return BASENAME;
  },
};
