import { Fragment, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FaXmark } from "react-icons/fa6";
import { modalActions } from "../../store";

import "./EditScenarioModal.css"
// import { useEffect } from "react";

// const Backdrop = (props) => {
//     return <div className={`backdrop ${props.visible ? "show" : ""}`} onClick={()=>props.modalClose()}></div>
// };


const EditScenarioModal = (props) => {
    const { width, height, mainClass } = props;
    const dispatch = useDispatch();
    const modalVisible = useSelector(state => state.modal.modalVisible);
    const [showModalClass, setShowModalClass] = useState(null);

    const ModelCloseHandler = () => {
        // setmodalVisible(false)
        setShowModalClass(false)
        setTimeout(() => {
            dispatch(modalActions.toggle(""))
        }, 200);
    }
    const stopPropagtionHandler = (e) => {
        e.stopPropagation()
    }

    const testingFunc = () => {
        setTimeout(() => {
            setShowModalClass(true)
        }, 200);
    }
    if (modalVisible) {
        testingFunc()
    }


    return modalVisible ? (
        <Fragment>
            <div className={`modal fade ${mainClass ? mainClass : ""} ${showModalClass ? "show" : ""}`} tabIndex="-1" role="dialog" aria-labelledby="overlay" onClick={ModelCloseHandler} style={{ display: `${modalVisible ? "block" : ""}` }}>
                <div className={`modal-content ${width ? width : "sm"} ${height ? height : ""}`} onClick={stopPropagtionHandler}>
                    <div className="head">
                        <h3 className="font-roboto text-black">
                            {props.title}
                        </h3>
                        <button onClick={ModelCloseHandler}><FaXmark /></button>
                    </div>
                    <div className="body">
                        {props.children}
                    </div>
                </div>
            </div>
        </Fragment>
    ) : null;
};

export default EditScenarioModal;