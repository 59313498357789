import { Fragment, useState } from "react";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";
import { BsPlusCircleFill } from "react-icons/bs";
import { BiSolidLock, BiChevronDown } from "react-icons/bi";
import EditScenarioModal from "../ScenarioPlanning/EditScenarioModal";
import Select, { components as selectComp } from "react-select";
import Dropdown from "./Dropdown";
import { modalActions } from "../../store";
import "./AddMember.css";

const AddMember = (props) => {
  const dispatch = useDispatch();
  const modalType = useSelector((state) => state.modal.modalType);
  // const [addMemModal, setaddMemModal] = useState(false)
  const Options = [
    { value: "1", label: "avatar-.jpg\nOliver Wings\noliver.wings@gmail.com" },
    { value: "2", label: "avatar4.jpg\nHande Ercel\nhande.ercel@gmail.com" },
    { value: "3", label: "avatar3-.jpg\nDanial Wilton\ndanial_w@gmail.com" },
  ];

  const [assignedUsers, setassignedUsers] = useState([
    { value: "1", label: "avatar2.jpg\nRogene Lacanienta\nr.l@gmail.com" },
    { value: "2", label: "avatar3.jpg\nPrashita Rao\nparshi@gmail.com" },
    { value: "3", label: "avatar.jpg\nAnusha Saad\naSaad@gmail.com" },
  ]);

  const UserProfileOption = ({ innerProps, label }) => {
    const [profile, name, email] = label.split("\n");

    return (
      <div
        {...innerProps}
        style={{ display: "flex", alignItems: "center", padding: "5px 20px" }}
      >
        <img
          src={`${process.env.PUBLIC_URL}/images/${profile}`} // Replace with the actual user profile image URL
          alt="User Profile"
          style={{
            width: "40px",
            height: "40px",
            marginRight: "10px",
            borderRadius: "100px",
          }}
        />
        <div>
          <div style={{ fontWeight: "bold" }}>{name}</div>
          <div>{email}</div>
        </div>
      </div>
    );
  };

  function SelectedUserProfile({ innerProps, data }) {
    const [profile, name] = data.label.split("\n");
    return (
      <div
        className="selUser"
        {...innerProps}
        style={{
          display: "flex",
          alignItems: "center",
          backgroundColor: "#cad1dd",
        }}
      >
        <img
          src={`${process.env.PUBLIC_URL}/images/${profile}`} // Replace with the actual user profile image URL
          alt="User Profile"
          style={{
            width: "20px",
            height: "20px",
            marginRight: "10px",
            borderRadius: "100px",
          }}
        />
        <div>
          <div>{name}</div>
        </div>
      </div>
    );
  }

  const customComponents = {
    Option: UserProfileOption,
  };

  const Placeholder = (props) => {
    return <selectComp.Placeholder {...props} />;
  };

  const handleChange = (option) => {
    console.log(option);
    const msgdiv = document.getElementById("msgDiv");
    msgdiv.style.display = "block";
  };

  const addMemberClickHandler = () => {
    // setaddMemModal(true);
    dispatch(modalActions.toggle("AddMember"));
  };

  const closeModalHandler = () => {
    dispatch(modalActions.toggle(""));
  };

  return (
    <Fragment>
      <div className="addMem-lkDs flex">
        {/* <!-- members --> */}
        <div className="members flex">
          <div className="avatar">
            <img
              src={`${process.env.PUBLIC_URL}/images/avatar.jpg`}
              alt="member"
            />
          </div>
          <div className="avatar">
            <img
              src={`${process.env.PUBLIC_URL}/images/avatar2.jpg`}
              alt="member"
            />
          </div>
          <div className="avatar">
            <img
              src={`${process.env.PUBLIC_URL}/images/avatar3.jpg`}
              alt="member"
            />
          </div>
          {/* <!-- add member button --> */}
          <button
            id="addMember"
            onClick={addMemberClickHandler}
            className="ml-5 text-primary"
            data-tooltip-id="bbTooltip"
            data-tooltip-content="Add Member"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0ZM3 19.235v-.11a6.375 6.375 0 0 1 12.75 0v.109A12.318 12.318 0 0 1 9.374 21c-2.331 0-4.512-.645-6.374-1.766Z"
                stroke="#4C4DFF"
                stroke-width="2.25"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            {/* <BsPlusCircleFill /> */}
          </button>
          {/* <!-- add member button --> */}
          <Tooltip id="bbTooltip" place="right" />
        </div>
        {/* <!-- members --> */}
      </div>
      {modalType === "AddMember" &&
        ReactDOM.createPortal(
          <EditScenarioModal title="Add Member" mainClass="tm" width={`sm`}>
            <Fragment>
              <div className="actionModalContent">
                <Select
                  onChange={handleChange}
                  className="select2 full-w user-select"
                  options={Options}
                  components={{
                    Option: customComponents.Option,
                    MultiValue: SelectedUserProfile,
                  }}
                  selectComp={{ Placeholder }}
                  placeholder={"Add member"}
                  isMulti
                />
                <p className="mt-3 font-bold">Members</p>
                <ul className="assignedUserList flex flex-wrap flex-col gap-y-1">
                  {assignedUsers.map((user, index) => {
                    const [avatar, username, email] = user.label.split("\n");
                    // console.log(typeof(user.label))
                    // console.log(username)
                    // console.log(email)
                    return (
                      <li
                        key={index}
                        className="inline-flex items-center gap-x-3"
                      >
                        <img
                          src={`${process.env.PUBLIC_URL}/images/${avatar}`}
                          alt="user image"
                        />
                        <div>
                          <div className="name">{username}</div>
                          <div className="email">{email}</div>
                        </div>
                        <div className="access">
                          <select name="accessType" id="">
                            <option value="1">Editor</option>
                            <option value="2">Viewer</option>
                            <option value="3">Remove Access</option>
                          </select>
                          {/* <Dropdown extraClass="accessTypeDD width-190">
                                                <button className="p-2">Editor <BiChevronDown /></button>
                                                <div className="dd-menu">
                                                    <ul>
                                                        <li><button>Viewer</button></li>
                                                        <li><button>Editor</button></li>
                                                    </ul>
                                                    <button className="!bg-transparent">Remove Access</button>
                                                </div>
                                            </Dropdown> */}
                        </div>
                      </li>
                    );
                  })}
                </ul>
                <label id="msgDiv" htmlFor="msg" style={{ display: "none" }}>
                  <textarea
                    name="msg"
                    id="msg"
                    rows="5"
                    placeholder="Message"
                  ></textarea>
                </label>
                <div className="accessType">
                  <p className="font-bold">General access</p>
                  <div>
                    <span>
                      <BiSolidLock />
                    </span>
                    <div className="text">
                      <h5>Restricted</h5>
                      <p>Only people with access can open the project.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer mt-3">
                <button
                  className="doneBtn !bg-primary text-white py-2.5 px-10 mt-3 font-semibold rounded-3xl"
                  onClick={closeModalHandler}
                >
                  Done
                </button>
              </div>
            </Fragment>
          </EditScenarioModal>,
          document.getElementById("modal-root")
        )}
    </Fragment>
  );
};

export default AddMember;
