import { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { ResourceOptimizerActions, sidebarActions } from "../../store";
import { timeManagerActions, costActions, projectManagerActions, vivclimaActions, VisionAIActions, estimationAppActions, riskManagementActions, gulerMakActions } from "../../store";
import SideBarButton from "./SideBarButton";
import SideBarMenuItem from "./SideBarMenuItem";
import "./Sidebar.css"
import { GiProgression } from "react-icons/gi";
import { GrShieldSecurity } from "react-icons/gr";
import { getProjectScheduleDetails } from "../../utils/getProjectScheduleDetails";
import getProjectIdentifier from "../../utils/getProjectIdentifier";
import axiosRequest from "../../axiosRequest";

export const LINK_MENU_ITEM = [
    {
        path: "/enterprise-directory",
        imgSrc: "/images/icons/projectdirectory.svg",
        alt: "project manager",
        text: "Enterprise Directory",
    },
    {
        path: "/neuro-dynamiq",
        imgSrc: "/images/products/calendar.svg",
        alt: "calendar",
        text: "NeuroDynamiq",
    },
    {
        path: "/vivclima",
        imgSrc: "/images/products/vivclima.svg",
        alt: "vivclima",
        text: "VivClima",
    },
    {
        path: "/abacus",
        imgSrc: "/images/estimation.png",
        alt: "Estimation",
        text: "Abacus",
    },
    {
        path: "/optima-res",
        imgSrc: "/images/AppScreen/optimizer.svg",
        alt: "OptimaRes",
        text: "OptimaRes",
    },
    {
        path: "/numetra",
        imgSrc: "/images/AppScreen/cost.svg",
        alt: "numetra",
        text: "Numetra",
    },
    {
        path: "/risk-sentinel",
        imgSrc: "/images/AppScreen/RM.svg",
        alt: "RiskSentinel",
        text: "RiskSentinel",
    },
    {
        path: "/saif",
        imgSrc: "/images/products/com_vision.svg",
        alt: "s AI f",
        text: "s AI f",
    },
];

export const LINK_MENU_ITEM_ALLIANCE = [
    {
        path: "/",
        imgSrc: "/images/icons/alliance-home.svg",
        alt: "home",
        text: "Home",
    },
    {
        path: "/neuro-dynamiq",
        imgSrc: "/images/products/calendar.svg",
        alt: "calendar",
        text: "NeuroDynamiq",
    },
    {
        path: "/vivclima",
        imgSrc: "/images/products/vivclima.svg",
        alt: "vivclima",
        text: "VivClima",
    },
    {
        path: "/abacus",
        imgSrc: "/images/estimation.png",
        alt: "Estimation",
        text: "Abacus",
    },
    {
        path: "/optima-res",
        imgSrc: "/images/AppScreen/optimizer.svg",
        alt: "OptimaRes",
        text: "OptimaRes",
    },
    {
        path: "/numetra",
        imgSrc: "/images/AppScreen/cost.svg",
        alt: "numetra",
        text: "Numetra",
    },
    {
        path: "/risk-sentinel",
        imgSrc: "/images/AppScreen/RM.svg",
        alt: "RiskSentinel",
        text: "RiskSentinel",
    },
    {
        path: "/saif",
        imgSrc: "/images/products/com_vision.svg",
        alt: "s AI f",
        text: "s AI f",
    },
];

const Sidebar = (props) => {
    const { switchView } = props
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const [projectDetails, setProjectDetails] = useState(null)
    const [activeElement, setActiveElement] = useState("");
    const isLoading = useSelector(state => state.generalLoader.loaderVisible);
    const tmActFea = useSelector(state => state.timeManager.activeFeature);
    const tmActIntFea = useSelector(state => state.timeManager.internalActiveFea);
    const [projectSidebarStats, setProjectSidebarStats] = useState(null)
    let projectName = localStorage.getItem("projectName");
    if (projectName === null || projectName === undefined || projectName === "undefined") {
        projectName = "Andarko Piling Project"
    }

    if (location.pathname === "/risk-sentinel") {
        projectName = "CSA"

    }

    const { displayMonitornControl } = useSelector((state) => state.riskManagement);



    useEffect(() => {
        if (displayMonitornControl) {
            featureHnadler("Risk Modelling")
        }
    }, [displayMonitornControl]);

    const activeFeature = useSelector(state => (
        location.pathname === "/neuro-dynamiq" ? state.timeManager.activeFeature :
            location.pathname === "/numetra" ? state.cost.activeFeature :
                location.pathname === "/enterprise-directory" ? state.ProjectManager.activeFeature :
                    location.pathname === "/vivclima" ? state.vivclima.activeFeature :
                        location.pathname === "/saif" ? state.VisionAI.activeFeature :
                            location.pathname === "/abacus" ? state.estimationApp.activeFeature :
                                location.pathname === "/optima-res" ? state.ResourceOptimizer.activeFeature :
                                    location.pathname === "/risk-sentinel" ? state.riskManagement.activeFeature :
                                        "Core"
    ))
    // const [sidebarOpen, setSidebarOpen] = useState(false);
    const sidebarOpen = useSelector(state => state.sidebar.sidebarOpen);

    const sidebarOpenerHandler = () => {
        // setSidebarOpen(!sidebarOpen)
        dispatch(sidebarActions.toggle())
    }

    //VivClima Values
    const [totalCarbonTax, setTotalCarbonTax] = useState("-");
    const [totalCarbonEmission, setTotalCarbonEmission] = useState("-");
    const [currentAQI, setCurrentAQI] = useState("-");

    useEffect(() => {
        const handleStorageChange = () => {
            setTotalCarbonTax(localStorage.getItem('totalCarbonTax'));
            setTotalCarbonEmission(localStorage.getItem('totalCarbonEmission'));
            setCurrentAQI(localStorage.getItem('currentAQI'));
        };
        window.addEventListener('storage', handleStorageChange);
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, [totalCarbonEmission, totalCarbonTax, currentAQI]);

    const { displayGanttChart } = useSelector((state) => state.timeManager);

    useEffect(() => {
        if (displayGanttChart) {
            featureHnadler("Core")
        }
    }, [displayGanttChart]);

    const featurePersistenceHandler = (sliceName, featureName) => {
        console.log("sliceName, featureName", sliceName, featureName)
        localStorage.setItem("persistedPageInfo", JSON.stringify({
            "lastVisitedFeature": featureName,
            "activeApp": location.pathname
        }));
        location.pathname === "/neuro-dynamiq" ?
            (tmActFea === "Core" && tmActIntFea !== "Overview" ?
                dispatch(sliceName.changeInternalFeature("Overview")) :
                dispatch(sliceName.changeFeature(featureName))) :
            dispatch(sliceName.changeFeature(featureName))
    }

    const featureHnadler = (payload) => {
        dispatch(projectManagerActions.changeFileView(false));
        setActiveElement(payload);
        location.pathname === "/neuro-dynamiq" ? featurePersistenceHandler(timeManagerActions, payload) :
            location.pathname === "/numetra" ? featurePersistenceHandler(costActions, payload) :
                location.pathname === "/enterprise-directory" ? featurePersistenceHandler(projectManagerActions, payload) :
                    location.pathname === "/vivclima" ? featurePersistenceHandler(vivclimaActions, payload) :
                        location.pathname === "/saif" ? featurePersistenceHandler(VisionAIActions, payload) :
                            location.pathname === "/abacus" ? featurePersistenceHandler(estimationAppActions, payload) :
                                location.pathname === "/optima-res" ? featurePersistenceHandler(ResourceOptimizerActions, payload) :
                                    location.pathname === "/risk-sentinel" ? featurePersistenceHandler(riskManagementActions, payload) :
                                        location.pathname === "/Gulermak" ? featurePersistenceHandler(gulerMakActions, payload) :
                                            featurePersistenceHandler(timeManagerActions, payload)
    }

    useEffect(() => {
        setActiveElement(
            location.pathname === '/neuro-dynamiq' ? 'Diagnostic' :
                location.pathname === '/numetra' ? 'Simulation' :
                    location.pathname === '/enterprise-directory' ? 'Core' :
                        location.pathname === '/vivclima' ? 'Simulation' :
                            location.pathname === '/saif' ? 'Diagnostic' :
                                location.pathname === '/optima-res' ? 'Diagnostic' :
                                    'Diagnostic'
        );
    }, [location.pathname]);

    useEffect(() => {
        const persistedPageInfo = JSON.parse(localStorage.getItem("persistedPageInfo"))
        // console.log("persistedPageInfo", persistedPageInfo)
        const app = persistedPageInfo?.activeApp
        const lastVisitedFeature = persistedPageInfo?.lastVisitedFeature
        if (lastVisitedFeature && location.pathname === app) {
            location.pathname === "/neuro-dynamiq" ? dispatch(timeManagerActions.changeFeature(lastVisitedFeature)) :
                location.pathname === "/numetra" ? dispatch(costActions.changeFeature(lastVisitedFeature)) :
                    location.pathname === "/enterprise-directory" ? dispatch(projectManagerActions.changeFeature(lastVisitedFeature)) :
                        location.pathname === "/vivclima" ? dispatch(vivclimaActions.changeFeature(lastVisitedFeature)) :
                            location.pathname === "/saif" ? dispatch(VisionAIActions.changeFeature(lastVisitedFeature)) :
                                location.pathname === "/abacus" ? dispatch(estimationAppActions.changeFeature(lastVisitedFeature)) :
                                    location.pathname === "/optima-res" ? dispatch(ResourceOptimizerActions.changeFeature(lastVisitedFeature)) :
                                        location.pathname === "/risk-sentinel" ? dispatch(riskManagementActions.changeFeature(lastVisitedFeature)) :
                                            location.pathname === "/Gulermak" ? dispatch(gulerMakActions.changeFeature(lastVisitedFeature)) :
                                                dispatch(timeManagerActions.changeFeature(lastVisitedFeature))
        }
    }, [])

    useEffect(() => {
        getProjectScheduleDetails(getProjectIdentifier())
            .then((response) => {
                setProjectDetails(response)
            })
    }, [])

    useEffect(() => {
        if (location.pathname === "/vivclima") {
            axiosRequest("proClima/getVivclimaDetails", "POST", { projectIdentifier: getProjectIdentifier() })
                .then((response) => {
                    // console.log("getVivclimaDetails sidebar", response)
                    setProjectSidebarStats(response.data.result[0])
                })
                .then((error) => console.log("error getVivclimaDetails", error))
        } else if (location.pathname === "/numetra") {
            axiosRequest("schedule/projectMetrics", "POST", { projectIdentifier: getProjectIdentifier() })
                .then((response) => {
                    console.log("projectMetrics sidebar", response)
                    setProjectSidebarStats(response.data.data)
                    localStorage.setItem("projectStats", JSON.stringify(response.data.data))
                })
                .then((error) => console.log("error projectMetrics", error))
        } else if (location.pathname === "/saif") {
            axiosRequest("saif/safetyMetrics", "POST", { projectIdentifier: getProjectIdentifier() })
                .then((response) => {
                    console.log("projectMetrics sidebar", response)
                    setProjectSidebarStats(response?.data)
                })
                .then((error) => console.log("error safetyMetrics", error))
        }

    }, [])

    return (
        <div className={`sidebar h-screen tm ${sidebarOpen ? "absolute open" : "fixed"} ${isLoading ? "loading" : ""}`}>
            <button className="close" onClick={sidebarOpenerHandler}><img src={`${process.env.PUBLIC_URL}/images/icons/sidebarArrow.svg`} alt="arrowIcon" /></button>
            {
                location.pathname === "/enterprise-directory" ?
                    <div className="scroll mt-36">
                        <ul>
                            <li><button onClick={() => featureHnadler("Recent projects")} className={activeFeature === "Recent projects" ? "active" : ""}><img src={`${process.env.PUBLIC_URL}/images/icons/projectDirectory/recent.svg`} alt="Recent projects" /> <span>Recent Projects</span></button></li>
                            <li><button onClick={() => featureHnadler("All Projects")} className={activeFeature === "All Projects" ? "active" : ""}><img src={`${process.env.PUBLIC_URL}/images/icons/projectDirectory/projects.svg`} alt="All Projects" /> <span>All Projects</span></button></li>
                            <li><button onClick={() => featureHnadler("Programs")} className={activeFeature === "Programs" ? "active" : ""}><img src={`${process.env.PUBLIC_URL}/images/icons/projectDirectory/programs.svg`} alt="All Projects" /> <span>Programs</span></button></li>
                            <li><button onClick={() => featureHnadler("Files")} className={activeFeature === "Files" ? "active" : ""}><img src={`${process.env.PUBLIC_URL}/images/icons/projectDirectory/files.svg`} alt="Files" /> <span>Files</span></button></li>
                        </ul>
                    </div>
                    :
                    <div className="scroll">
                        <div className="projInfo">
                            {
                                location.pathname === "/saif" ?
                                    <img src={`${process.env.PUBLIC_URL}/images/logo-123.jpg`} alt="sidebar logo" style={{ borderRadius: "50%" }} />
                                    :
                                    location.pathname === "/risk-sentinel" ?
                                        <img src={`${process.env.PUBLIC_URL}/images/csa.jpg`} alt="sidebar logo" style={{ borderRadius: "50%" }} />
                                        :
                                        location.pathname === "/alliance" ?
                                            <img src={`${process.env.PUBLIC_URL}/images/laing-o-rouke-project-logo.png`} alt="sidebar logo" />
                                            :
                                            <img src={`${process.env.PUBLIC_URL}/images/DCM_Logo.jpg`} alt="sidebar logo" style={{ borderRadius: "50%" }} />
                            }
                            {
                                location.pathname === "/alliance" ?
                                    <div>
                                        <h2>Eastern Freeway – Burke to Tram Alliance</h2>
                                        <div className="flex flex-wrap justify-between">
                                            <span><small>Start Date</small>2022-05-09</span>
                                            <span><small>Remaining <br />Duration</small>594 Days</span>
                                            <span><small>Duration</small>1,463 Days</span>
                                        </div>
                                    </div>
                                    : location.pathname === "/neuro-dynamiq" || location.pathname === "/abacus" || location.pathname === "/optima-res" || location.pathname === "/risk-sentinel" || location.pathname === "/Gulermak" ?
                                        <div>
                                            <h2>{projectName}</h2>
                                            <div className="flex flex-wrap justify-between">
                                                <span><small>Start Date</small>{projectDetails?.startDate}</span>
                                                <span><small>Remaining <br />Duration</small>{projectDetails?.remainingDuration} Days</span>
                                                <span><small>Duration</small>{projectDetails?.duration} Days</span>
                                            </div>
                                        </div>
                                        : location.pathname === "/numetra" ?
                                            <div className="mb-4">
                                                <h2>{projectName}</h2>
                                                <div className="flex flex-wrap justify-between">
                                                    <span style={{ fontSize: "16px" }}><small>Budget</small>${projectSidebarStats?.budget.toString().slice(0, 2)}M</span>
                                                    <span style={{ fontSize: "16px" }}><small>ETC</small>${projectSidebarStats?.etc.toString().slice(0, 2)}M</span>
                                                    <span style={{ fontSize: "16px" }}><small>EAC</small>${projectSidebarStats?.eac.toString().slice(0, 2)}M</span>
                                                </div>
                                            </div>
                                            : location.pathname === "/vivclima" ?
                                                <div className="mb-4">
                                                    <h2>{projectName}</h2>
                                                    <div className="flex flex-wrap justify-between">
                                                        <span style={{ fontSize: "15px" }}><small>Carbon Tax</small>${projectSidebarStats ? projectSidebarStats["Carbon Tax"] : null}</span>
                                                        <span style={{ fontSize: "15px" }}><small>Emission</small>{projectSidebarStats?.Emission}</span>
                                                        <span style={{ fontSize: "15px" }}><small>Air Quality</small>{projectSidebarStats ? projectSidebarStats["Air Quality"] : null}</span>
                                                    </div>
                                                </div>
                                                : location.pathname === "/saif" ?
                                                    <div className="mb-4">
                                                        <h2>Expo Upgrade Program</h2>
                                                        <div className="flex flex-wrap justify-between">
                                                            <span style={{ fontSize: "15px" }}><small>Safety<br />Incident</small>{projectSidebarStats?.safetyIncidents?.current}</span>
                                                            <span style={{ fontSize: "15px" }}><small>Safety<br />Training</small>{projectSidebarStats?.safetyTraining?.notCertified}</span>
                                                            <span style={{ fontSize: "15px" }}><small>LTIFR</small>{projectSidebarStats?.ltifr?.current}</span>
                                                        </div>
                                                    </div>
                                                    : null
                            }
                        </div>
                        <div className={`projFeaturesCircle ${activeElement === ("Diagnostics" || "Simulation") ? "one-active" : activeElement === "Pattern Recognition" ? "two-active" : activeElement === "Predictions" ? "three-active" : "one-active"}`}>
                            <div className="circle">
                                <img src={`${process.env.PUBLIC_URL}/images/tmCircleLogo.svg`} alt="circle logo" />
                            </div>
                            {
                                location.pathname === "/vivclima" || location.pathname === "/risk-sentinel" ?
                                    <button className={activeElement === "Simulation" ? "feature one active" : "feature one"} onClick={() => featureHnadler("Diagnostics")}><span>Diagnostics</span></button>
                                    :
                                    <SideBarButton className={activeElement === "Diagnostics" ? "feature one active" : "feature one"} tooltipId='dia1' disable={false} onClick={() => featureHnadler("Diagnostics")} activeFeature={activeElement} title="Diagnostics" />
                                // <button className={activeElement === "Diagnostics" ? "feature one active" : "feature one"} onClick={() => featureHnadler("Diagnostics")}><span>Diagnostics</span></button>
                            }
                            <SideBarButton className={activeElement === "Pattern Recognition" ? "feature two active" : "feature two"} tooltipId='pr1' disable={false} onClick={() => featureHnadler("Pattern Recognition")} activeFeature={activeElement} title="Pattern Recognition" />
                            <SideBarButton className={activeElement === "Predictions" ? "feature three active" : "feature three"} tooltipId='pre2' disable={false} onClick={() => featureHnadler("Predictions")} activeFeature={activeElement} title="Predictions" />
                        </div>
                        {!switchView ?
                            <ul>
                                <li><button onClick={() => featureHnadler("Core")} className={activeFeature === "Core" ? "active" : ""}><img src={`${process.env.PUBLIC_URL}/images/icons/timemanager/core.svg`} alt="core" /> <span>Core</span></button></li>
                                <li><button onClick={() => featureHnadler("Oculus DV")} className={activeFeature === "Oculus DV" ? "active" : ""} ><img src={`${process.env.PUBLIC_URL}/images/icons/timemanager/oculus.svg`} alt="oculus" /> <span>Oculus DV</span></button></li>
                                <SideBarMenuItem tooltipId='andon' disable={false} onClick={() => featureHnadler("Andon")} activeFeature={activeElement} imgSrc={`${process.env.PUBLIC_URL}/images/icons/timemanager/andon.svg`} title="Andon" />
                                {
                                    location.pathname !== "/visionai" &&
                                        location.pathname !== "/vivclima" &&
                                        location.pathname !== "/optima-res" &&
                                        // location.pathname !== "/riskmanager" &&
                                        location.pathname !== "/abacus" &&
                                        location.pathname !== "/saif" ?
                                        <Fragment>
                                            <SideBarMenuItem tooltipId='gigo' disable={false} onClick={() => featureHnadler("GIGO")} activeFeature={activeElement} imgSrc={`${process.env.PUBLIC_URL}/images/icons/timemanager/gigo.svg`} title="GIGO" />
                                            {
                                                location.pathname !== ("/numetra" && "/abacus" && "/vivclima" && "/risk-sentinel") ?
                                                    <SideBarMenuItem tooltipId='sce_mod' disable={false} onClick={() => featureHnadler("Scenario Modeling")} activeFeature={activeElement} imgSrc={`${process.env.PUBLIC_URL}/images/icons/timemanager/sce_mod.svg`} title="Scenario Modeling" />
                                                    // <li><button onClick={() => featureHnadler("Scenario Modeling")} className={activeFeature === "Scenario Modeling" ? "active" : ""}><img src={`${process.env.PUBLIC_URL}/images/icons/timemanager/sce_mod.svg`} alt="sce_mod" /> <span>Scenario Modeling</span></button></li>
                                                    : null
                                            }
                                        </Fragment>
                                        : null
                                }
                                {
                                    location.pathname == ("/risk-sentinel") ?
                                        <Fragment>
                                            <li><button onClick={() => featureHnadler("Risk Modelling")} className={activeFeature === "Risk Modelling" ? "active" : ""}><GrShieldSecurity className="h-6 w-6 ml-[3px]" /> <span>Risk Modelling</span></button></li>
                                            {/* <li><button onClick={()=>featureHnadler("RM Forms")} className={activeFeature === "RM Forms" ? "active" : ""}><img src={`${process.env.PUBLIC_URL}/images/icons/rmForm.svg`} alt="RM Forms" /> <span>RM Forms</span></button></li> */}
                                        </Fragment>
                                        : null
                                }
                                {
                                    location.pathname == ("/optima-res") ?
                                        <Fragment>
                                            <li><button onClick={() => featureHnadler("Progress Tracker")} className={activeFeature === "Progress Tracker" ? "active" : ""}><GiProgression /> <span>Progress Tracker</span></button></li>
                                            <li><button onClick={() => featureHnadler("+ Resources")} className={activeFeature === "+ Resources" ? "active" : ""}><img src={`${process.env.PUBLIC_URL}/images/icons/timemanager/gigo.svg`} alt="+ Resources" /> <span>+ Resources</span></button></li>
                                        </Fragment>
                                        : null
                                }
                                {
                                    location.pathname === "/vivclima" ?
                                        <Fragment>
                                            <li><button onClick={() => featureHnadler("Air Quality")} className={activeFeature === "Air Quality" ? "active" : ""}><img src={`${process.env.PUBLIC_URL}/images/icons/sidebar/airquality.png`} style={{ width: "28px", height: "28px" }} alt="AirQuality" /> <span>Air Quality</span></button></li>
                                            <li><button onClick={() => featureHnadler("Climate Risk Assesment")} className={activeFeature === "Climate Risk Assesment" ? "active" : ""}><img src={`${process.env.PUBLIC_URL}/images/icons/sidebar/climaterisk.png`} style={{ width: "28px", height: "28px" }} alt="Climate Risk Assesment" /> <span>Climate Risk</span></button></li>
                                        </Fragment>
                                        : null
                                }
                                {
                                    location.pathname === "/abacus" ?
                                        <li><button onClick={() => featureHnadler("Build your Bid")} className={activeFeature === "Build your Bid" ? "active" : ""}><img src={`${process.env.PUBLIC_URL}/images/icons/sidebar/airquality.png`} style={{ width: "28px", height: "28px" }} alt="Build your Bid" /> <span>Build your Bid</span></button></li>
                                        : null
                                }
                            </ul> :
                            <ul>
                                {LINK_MENU_ITEM_ALLIANCE.map((item, index) => <SideBarMenuItem tooltipId={`Linkpng${index}`} disable={false} onClick={() => navigate(item.path)} activeFeature={activeElement} imgSrc={`${process.env.PUBLIC_URL}${item.imgSrc}`} title={item.text} />)}
                            </ul>
                        }
                    </div>
            }
        </div>
    )
};

export default Sidebar;
