import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import EditScenarioModal from "../ScenarioPlanning/EditScenarioModal";
import ToastMessage from "./ToastMessage";
import { FiEyeOff, FiCheckCircle } from "react-icons/fi";
import axiosRequest from "../../axiosRequest";
import { modalActions } from "../../store";

import "./ChangePasswordModal.css"

const ChangePasswordModal = (props) => {
    const navigate = useNavigate();
    const [emailError, setEmailError] = useState('');
    const [currentPasswordError, setCurrentPasswordError] = useState('');
    const [newPasswordError, setNewPasswordError] = useState('');

    const updatePasswordDetails = {
        email: "",
        oldPassword: "",
        newPassword: ""
    }

    const [passwordInfoObj, setPasswordInfoObj] =  useState(updatePasswordDetails)

    const [sendPassword, setSendPassword] = useState(false);
    
    const showPassHandler = (e) => {
        const label = e.target.closest('label');
        if (label) {
            // Find the input element within the label using querySelector
            const input = label.querySelector('input');
        
            if (input) {
              // You can now access and manipulate the input element
                if (input.type === "password") {
                    input.type = "text";
                    e.target.innerHTML = '<svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg>';
                } else {
                    input.type= "password";
                    e.target.innerHTML = '<svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path><line x1="1" y1="1" x2="23" y2="23"></line></svg>';
                }
            }
        }
    }

    const logoutHandler = () => {
        localStorage.clear();
        document.cookie = "email=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        navigate("/login");
    }

    const submitFormRequest = (params) => {
        axiosRequest("changepassword/updatePassword", "POST", params)
        .then((data)=>{
            // console.log("data", data)
            if(data.data === "Password updated.") {
                setSendPassword(true)
                setTimeout(() => {
                    logoutHandler()
                }, 2000);
            } else {
                toggleToast(data.data)
            }
            // toggleToast(data, 'success')
        })
        .catch(error => {
            // console.error("forgotpassword/changeForgotPassword error", error)
            toggleToast(error.data)
        });
    }

    const updatePasswordFormSubmitHandler = () => {
        // Validate email format
        // const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        // if (!emailPattern.test(passwordInfoObj.email)) {
        //     setEmailError('Enter a valid email address');
        //     return;
        // }
        // setEmailError('');
        
        const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=!])[a-zA-Z\d@#$%^&+=!]{6,}$/;

        // Validate password length
        // if (passwordInfoObj.oldPassword?.length < 6) {
        //     setCurrentPasswordError('Current password must be at least 6 characters long');
        //     return;
        // }

        // password should have one uppercase, one lowercase, one number and one special character
        // if (!passwordPattern.test(passwordInfoObj.oldPassword)) {
        //     setCurrentPasswordError('Current password should have at least one uppercase, one lowercase, one number and one special character');
        //     return;
        // }
        // setCurrentPasswordError('');


        if (passwordInfoObj.newPassword?.length < 6) {
            setNewPasswordError('New password must be at least 6 characters long');
            return;
        }
        
        if (!passwordPattern.test(passwordInfoObj.newPassword)) {
            setNewPasswordError('New password should have at least one uppercase, one lowercase, one number and one special character');
            return;
        }
        setNewPasswordError('');

        // send API request
        submitFormRequest(passwordInfoObj)
    }

    // toast
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastType, setToastType] = useState('error');
  
    const toggleToast = (message = '', type = 'error') => {
        setToastMessage(message);
        setToastType(type)
        setShowToast(showToast ? false : true);
    };

    return (
        <>
        <EditScenarioModal mainClass="passwordProfile" title="Change your Password">
            {
                !sendPassword ?
                // <div className="passCont">
                //     <label htmlFor="currentPassword">
                //         Current Password
                //         <input id="currentPassword" type="password" value="testing123" />
                //         <button onClick={(e)=>showPassHandler(e)}><FiEyeOff /></button>
                //     </label>
                //     <label htmlFor="newPassword">
                //         New Password
                //         <input id="newPassword" type="password" value="testing123" />
                //         <button onClick={(e)=>showPassHandler(e)}><FiEyeOff /></button>
                //     </label>
                //     <label htmlFor="confirmPassword">
                //         Confirm Password
                //         <input id="confirmPassword" type="password" value="testing123" />
                //         <button onClick={(e)=>showPassHandler(e)}><FiEyeOff /></button>
                //     </label>
                //     <button onClick={()=>setSendPassword(true)}>Set Password</button>
                // </div>
                <div className="passCont">
                    <label htmlFor="emailAddress">
                        Email Address
                        <input id="emailAddress" type="email" value={passwordInfoObj.email} onChange={(e)=>{setPasswordInfoObj({...passwordInfoObj, email: e.target.value})}} />
                    </label>
                    {emailError && (
                        <div className="text-red text-xs mb-1 flex items-center validationMsg">
                            <img src={`${process.env.PUBLIC_URL}/images/Login/warning.svg`} alt="warning-icon" className="w-4 h-4 mr-1" />
                            {emailError}
                        </div>
                    )}
                    <label htmlFor="currentPassword">
                        Current Password
                        <input id="currentPassword" type="password" value={passwordInfoObj.oldPassword} onChange={(e)=>{setPasswordInfoObj({...passwordInfoObj, oldPassword: e.target.value})}} />
                        <button onClick={(e)=>showPassHandler(e)}><FiEyeOff /></button>
                    </label>
                    {currentPasswordError && (
                        <div className="text-red text-xs mb-1 flex items-center validationMsg">
                            <img src={`${process.env.PUBLIC_URL}/images/Login/warning.svg`} alt="warning-icon" className="w-4 h-4 mr-1" />
                            {currentPasswordError}
                        </div>
                    )}
                    <label htmlFor="newPassword">
                        New Password
                        <input id="newPassword" type="password" value={passwordInfoObj.newPassword} onChange={(e)=>{setPasswordInfoObj({...passwordInfoObj, newPassword: e.target.value})}} />
                        <button onClick={(e)=>showPassHandler(e)}><FiEyeOff /></button>
                    </label>
                    {newPasswordError && (
                        <div className="text-red text-xs mb-1 flex items-center validationMsg">
                            <img src={`${process.env.PUBLIC_URL}/images/Login/warning.svg`} alt="warning-icon" className="w-4 h-4 mr-1" />
                            {newPasswordError}
                        </div>
                    )}
                    
                    <button onClick={updatePasswordFormSubmitHandler}>Update Password</button>
                </div>
                :
                <div className="passCont">
                    <div className="success-box">
                        <FiCheckCircle />
                        <p>Password has been changed</p>
                    </div>
                    {/* <button onClick={()=> dispatch(modalActions.toggle(""))}>Close</button> */}
                </div>
            }
        </EditScenarioModal>
        <ToastMessage message={toastMessage} type={toastType} onClose={() => toggleToast()} />
        </>
    )
};

export default ChangePasswordModal;
    