import axiosRequest from "../axiosRequest";

export const getProjectScheduleDetails = (projectId) => {
    return axiosRequest("earlyWarning/getProjectScheduleDetails", "POST", { projectIdentifier: projectId })
    .then((response)=>{
        // console.log("earlyWarning/getProjectScheduleDetails", response);
        return response.data.scheduleDetails;
    }).catch((error) => {
        console.log("earlyWarning/getProjectScheduleDetails", error);
    });
}