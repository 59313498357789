import React from 'react'
import { Tooltip } from "react-tooltip";

const SideBarButton = (props) => {
    const { onClick, title, disable, tootTipText, tooltipId, className } = props;

    const handleOnClick = () => {
        if (!disable) {
            onClick && onClick();
        }
    }

    return (
        <>
            <button
                disabled={disable}
                data-tooltip-id={`ddSidebarMenu${tooltipId}`}
                data-tooltip-content={tootTipText || "This feature is disabled"}
                className={className}
                onClick={handleOnClick}>
                <span style={{ color: disable ? 'grey' : ''}}>{title}</span>
            </button>
            {disable && <Tooltip id={`ddSidebarMenu${tooltipId}`} place="top" style={{zIndex:3}} />}
        </>
    )
}

export default SideBarButton